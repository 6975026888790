import styled from 'styled-components'
import { device } from 'src/styles/breakpoints'
import { orange, gray, white } from '../../styles/colors'

export const Wrapper = styled.div`
  h1, h2, h3, h4, h5, .tel-header {
    font-family: 'Sora';
  }

  h2 {
    margin-bottom: 20px;
  }
`
export const ContentHeader = styled.section`
  align-items: center;
  display: flex;
  min-height: calc(100vh - 64px);

  @media ${device.tablet} {
    min-height: 473px;
  }

  @media ${device.desktopLG} {
    min-height: calc(100vh - 73px);
  }

  .bread-crumb {
    span {
      color: ${gray['800']} !important;

      a {
        color: ${gray['800']};
        font-weight: 900;

        &:hover {
          color: ${orange.base} !important;
        }
      }
    }
  }
`

export const BoxedShadow = styled.div`
  max-width: 100%;
  padding: 15px 20px;
  align-items: center;
  background-color: ${white};
  border-radius: 8px;
  box-shadow: 0 5px 20px 0 rgba(0,0,0,.07);
  clear: both;
  display: flex;
  margin-bottom: 28px;

  @media ${device.tablet} {
    padding-right: 10px;
  }
`

export const ImageSize = styled.div`  
  .gatsby-image-wrapper {
    width: auto;
    @media ${device.desktopLG} {
      width: 456px;
      float: right;
    }
  }
`

export const ImageSizeLeft = styled(ImageSize)`  
  .gatsby-image-wrapper {
    @media ${device.desktopLG} {
      float: left;
    }
  }
`

export const UL = styled.ul`
  margin: 0;
  padding: 0;
  list-style: none;
  li {
    a {
      margin-left: 15px;
    }
  }
`

export const BoxCard = styled.div`

  @media ${device.tablet} {
    min-height: 200px;
    
    .voce-por-dentro {
      min-height: 178px;
      @media ${device.desktopXL} {
        min-height: 140px;
      }
    }
  }

  .collapse {
    padding: 0 5px 0 15px;

    .collapse-content {
      padding: 0;

      .arrow {
        margin-top: 8px;

        &--open {
          filter: initial;
        }
      }    
    }
    &.collapse--open {

      h3 {
        margin-top: 10px;
      }
      .details {

       .details-content {
          padding-left: 0;
          padding-right: 5px;
        }
      }
    }
  } 
`
