
type ScrollToSectionProps = {
  id: string;
}

export const ScrollToSection = ({ id }: ScrollToSectionProps) => {
  const scrollToSectionId = () => {
    const formElement = document.getElementById(id)
    if (formElement) {
      formElement.scrollIntoView({ behavior: 'smooth' })
    }
  }

  return (
    scrollToSectionId()
  )
}
