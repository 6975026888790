import styled from 'styled-components'
import { white, gray, grayscale } from '../../../../styles/colors'
import { device } from 'src/styles/breakpoints'

import { ChevronDown } from '../../../../assets/images/svg/chevron-down.svg'

export const SpeakOmbudsman = styled.div`  
  .form-hooks > .body-form {
    display: flex;
    flex-wrap: wrap;

    label {
      color: ${grayscale[500]};
    }

    .text-error-select {
      margin-top: 1px;
    }

    button {
      height: 39px;
    }

    input, textarea {
      background: ${gray['400']};
      border-radius: 5px;
      border: none;
      font-size: 15px;
      line-height: 20px;
      padding: 10px;
      font-weight: 300;
      color: ${gray['600']};

      &.hasError {
        border: 1px solid red;
      }
    }

    textarea {
      min-height: 104px;

      @media ${device.desktopLG} {
        min-height: 127px;
      }
    }
  
    span.select {
      background: ${gray['400']};
      border-radius: 5px;
      display: block;
      position: relative;
      outline: none;
      overflow: hidden;
      width: 100%;

      &:after {
        content: "";
        border-color: transparent transparent ${gray['600']} ${gray['600']};
        border-style: solid;
        border-width: 2px;
        height: 9px;
        margin-top: -7px;
        padding: 0;
        pointer-events: none;
        position: absolute;
        right: 14px;
        top: 50%;
        transform: rotate(315deg);
        transition: all .2s ease-in-out;
        width: 9px;
      }
    
      select {
        cursor: pointer;
        appearance: none;
        background: ${gray['400']}; 
        border: 0;
        border-radius: 5px;
        font-size: 15px;
        padding: 8px;
        width: 100%;
      }
    }  

    .field--label-error {
      font-size: 12px;
      color: red;
    }

    .wrapField-select {
      position: relative;
      cursor: pointer;

      .custom-select-value{
        width: 100%;
        height: 48px;
        padding: 15px;
        color: ${gray['600']};
        font-size: 15px;
        background-color: ${gray['400']};
        border-radius: 5px;
        border: none;
        line-height: 20px;
      }

      .icon-chevronDown{
        position: absolute;
        top: 16px;
        right: 10px;
        width: 48px;
        display: flex;
        align-items: center;

      span.icon{
        -webkit-mask: url(${ChevronDown}) no-repeat center;
        mask: url(${ChevronDown}) no-repeat center;
        -webkit-mask-size: cover;
        mask-size: cover;
        background-color: ${gray['600']};
        display: block;
        width: 24px;
        margin: 0 auto;
        height: 16px;
      }
    }
  }

  .form-border-bottom {
    background-color: #DEDFE4;
    height: 1px;
    width: 100%;
  }

  .custom--select-options {

    ul {
      list-style: none;
      background-color: ${white};
      box-shadow: 0 3px 2px #cccccc;
      padding: 0;

      li {
        cursor: pointer;
        font-size: 15px;
        padding: 5px 10px;
        background-color: ${white};

        &:hover {
          background-color: ${gray['100']};
        }
      }
    }
  }

  .custom-radio {
    display: flex;
    
    .radio {
      width: 100px;
    }

    .radio > label {
      display: flex;
      align-items: center;
      flex-direction: row-reverse;
      margin-bottom: 0;
      font-size: 14px;
      position: relative;
      cursor: pointer;

        input {
          margin-right: 10px;
          opacity: 0;
        }

        .help {
          display: block;
          width: 20px;
          height: 20px;
        }

        .help::before {
          display: block;
          position: absolute;
          content: '';
          width: 20px;
          height: 20px;
          border: 2px solid ${white};
          border-radius: 50%;
          top: 2px;
          left: 0;
        }

        input:checked + .help::after {
          display: block;
          position: absolute;
          content: '';
          width: 12px;
          height: 12px;
          background-color: ${white};
          border-radius: 50%;
          left: 4px;
          top: 6px;
          opacity: 1;
        }
      }
    }
  }
`
