import { useStaticQuery, graphql } from 'gatsby'

const PageQuery = () => {
  const pageQuery = useStaticQuery(graphql`
    query {
      comoPodemosAjudar: imageSharp(fluid: {originalName: { regex: "/como-podemos-ajudar/" }}) {
        fluid(maxWidth: 456, quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
      ajudamosVoce: imageSharp(fluid: {originalName: { regex: "/ajudamos-voce/" }}) {
        fluid(maxWidth: 456, quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
      nossosRelatorios: imageSharp(fluid: {originalName: { regex: "/nossos-relatorios/" }}) {
        fluid(maxWidth: 456, quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  `)

  return pageQuery
}

export default PageQuery
