import Img from 'gatsby-image'
import React, { useEffect, useRef } from 'react'
import { animated, useTrail } from 'react-spring'
import useScroll from '../../hooks/window/useScroll'
import isVisible from '../../utils/isVisible'

import Breadcrumb from 'src/components/Breadcrumb'
import Icon from 'src/components/UI/MarkdownIcon'
import useDataLayer from 'src/hooks/useDataLayer/dataLayerBody'
import Layout from 'src/layouts/BaseLayout'
import FaleOuvidoria from '../../components/UI/Forms/FaleOuvidoria/index'

import { BoxCard, BoxedShadow, ContentHeader, ImageSize, ImageSizeLeft, UL, Wrapper } from './style'

import OmbudsmanReportsJSON from 'src/assets/data/ouvidoria/relatorios-ouvidoria.json'
import StayInJSON from './assets/images/data/StayIn.json'

import pageContext from './pageContext.json'
import usePageQuery from './pageQuery'

const fadeFrom = {
  config: {
    duration: 500,
  },
  opacity: 0,
}

const fadeTo = {
  opacity: 1,
}

type OmbudsmanProps = {
  uuid: string;
  pdf: {
    name: string;
    file: {
      name: string;
      url: string;
    };
  };
}

type StayInProps = {
  icon: string;
  title: string;
  description: string;
  buttonText: string;
  url: string;
  dataValue: string;
}

function Ouvidoria () {
  const data = usePageQuery()
  const scroll = useScroll(300)

  // Refs
  const headerRef = useRef<HTMLElement>(null)
  const weAreHereRef = useRef<HTMLElement>(null)
  const weHelpYouRef = useRef<HTMLElement>(null)
  const reportsRef = useRef<HTMLElement>(null)
  const newsRef = useRef<HTMLElement>(null)
  const formRef = useRef<HTMLElement>(null)

  const [ sendDatalayerEvent ] = useDataLayer()

  // Animations
  const [ animatedHeader, setAnimatedHeader ] = useTrail(3, () => (fadeFrom))
  const [ animatedWeAreHere, setAnimatedWeAreHere ] = useTrail(3, () => (fadeFrom))
  const [ animatedWeHelpYou, setAnimatedWeHelpYou ] = useTrail(3, () => (fadeFrom))
  const [ animatedReports, setAnimatedReports ] = useTrail(3, () => (fadeFrom))
  const [ animatedNews, setAnimatedNews ] = useTrail(3, () => (fadeFrom))
  const [ animatedForm, setAnimatedForm ] = useTrail(3, () => (fadeFrom))

  useEffect(() => {
    if (isVisible(headerRef.current, -0.3)) {
      setAnimatedHeader((fadeTo))
    }
    if (isVisible(weAreHereRef.current, -0.3)) {
      setAnimatedWeAreHere((fadeTo))
    }
    if (isVisible(weHelpYouRef.current, -0.3)) {
      setAnimatedWeHelpYou((fadeTo))
    }
    if (isVisible(reportsRef.current, -0.3)) {
      setAnimatedReports((fadeTo))
    }
    if (isVisible(newsRef.current, -0.3)) {
      setAnimatedNews((fadeTo))
    }
    if (isVisible(formRef.current, -0.3)) {
      setAnimatedForm((fadeTo))
    }
  }, [ scroll ])

  return (
    <Wrapper>
      <Layout pageContext={pageContext}>
        <ContentHeader ref={headerRef} className='py-5 bg-white'>
          <div className='container'>
            <div className='row justify-content-center align-items-center'>
              <animated.div style={animatedHeader[2]} className='col-12 col-md-6 col-xl-5 offset-lg-1 offset-xl-2 order-md-last'>
                <ImageSize>
                  <Img fluid={data.comoPodemosAjudar.fluid} alt='Atendente com headdset' />
                </ImageSize>
              </animated.div>
              <div className='col-12 col-md-6 col-lg-5 mt-3'>
                <div className='col-12 d-none d-md-flex fixed-bread pl-0 mb-3'>
                  <Breadcrumb
                    sectionName='Como podemos te ajudar?'
                    type='Canais de Atendimento'
                    text='Ouvidoria'
                    link='/ouvidoria'
                  />
                </div>
                <animated.h1 style={animatedHeader[0]} className='fs-32 fs-lg-40 fs-xl-48 lh-40 lh-lg-50 lh-xl-60 fw-600 text-grayscale--500'>
                  Como podemos te ajudar?
                </animated.h1>
                <animated.p style={animatedHeader[1]} className='fs-18 lh-22 text-grayscale--500'>
                  A ouvidoria é um canal aberto para te ouvir. Uma alternativa para você que recebeu atendimento na central de relacionamento, mas não ficou satisfeito com a solução.
                </animated.p>
                <animated.p style={animatedHeader[1]} className='text-grayscale--500'>
                  Se você já tem um protocolo registrado, fale com a gente:
                </animated.p>
                <animated.p style={animatedHeader[1]} className='text-grayscale--500 fw-600 mb-0'>Ouvidoria:
                  <span className='text-orange--extra tel-header'>
                    <a
                      href='tel:+08009407772'
                      data-home-ouvidoria='hero'
                      onClick={() => {
                        sendDatalayerEvent({
                          section: 'dobra_01',
                          element_action: 'click button',
                          element_name: '0800 940 7772',
                          section_name: 'Como podemos te ajudar?',
                          redirect_url: 'tel:+08009407772',
                        })
                      }}
                    >
                      {' '}0800 940 7772
                    </a>
                  </span>
                </animated.p>
              </div>
            </div>
          </div>
        </ContentHeader>

        <section ref={weAreHereRef} className='bg-gray py-4 py-md-5'>
          <div className='container'>
            <div className='row align-items-center'>
              <div className='col-12 col-lg-5 col-xl-4'>
                <animated.h2 style={animatedWeAreHere[0]} className='fs-24 fs-lg-40 lh-30 lh-lg-50 text-md-center text-lg-left text-grayscale--500'>
                  Estamos aqui para te atender
                </animated.h2>
                <animated.p style={animatedWeAreHere[1]} className='fs-18 lh-22 text-md-center text-lg-left text-grayscale--500'>
                  Além do telefone da ouvidoria, nossos outros canais estão à disposição e você pode escolher a melhor forma de falar com a gente. <span className='fw-600 d-lg-block'>Nosso horário de atendimento é em dias úteis das 9h às 18h.</span>
                </animated.p>
              </div>
              <div className='col-12 col-lg-6 col-xl-7 offset-lg-1 mt-3'>
                <animated.div style={animatedWeAreHere[2]} className='col-12'>
                  <BoxedShadow className='h-100 d-flex justify-content-between'>
                    <div className='pr-3'>
                      <Icon width='32' height='32' color='orange--base' icon='action/phone' directory='v2' />
                    </div>
                    <div className='d-block'>
                      <p className='fs-18 lh-22 text-grayscale--500 fw-600'>SAC</p>
                      <p className='fs-18 lh-22 text-grayscale--500'>
                        É o seu primeiro contato? Fale com nosso SAC, no
                        <span className='text-orange--extra fw-600'>
                          <a
                            href='tel:+08009409999'
                            data-home-ouvidoria='sac'
                            onClick={() => {
                              sendDatalayerEvent({
                                section: 'dobra_02',
                                element_action: 'click button',
                                element_name: '0800 940 9999',
                                section_name: 'Estamos aqui para te atender',
                                redirect_url: 'tel:+08009409999',
                              })
                            }}
                          >
                            {' '}0800 940 9999.
                          </a>
                        </span>
                        Ou pelo
                        <span className='text-orange--extra fw-600'>
                          <a
                            href='tel:+30034070'
                            data-home-ouvidoria='sac-capitais'
                            onClick={() => {
                              sendDatalayerEvent({
                                section: 'dobra_02',
                                element_action: 'click button',
                                element_name: '3003-4070',
                                section_name: 'Estamos aqui para te atender',
                                redirect_url: 'tel:+30034070',
                              })
                            }}
                          >{' '}3003-4070
                          </a>
                        </span> (para capitais e regiões metropolitanas).
                      </p>
                    </div>
                  </BoxedShadow>
                </animated.div>
                <animated.div style={animatedWeAreHere[2]} className='col-12'>
                  <BoxedShadow className='h-100 d-flex justify-content-between'>
                    <div className='pr-3'>
                      <Icon width='32' height='32' color='orange--base' icon='action/help2' directory='v2' />
                    </div>
                    <div className='d-block pr-0'>
                      <p className='fs-18 lh-22 text-grayscale--500 fw-600'>Central de ajuda</p>
                      <p className='fs-18 lh-22 text-grayscale--500'>
                        Fale agora na nossa
                        <span className='text-orange--extra fw-600'>
                          <a
                            href='https://ajuda.bancointer.com.br'
                            target='blank'
                            data-home-ouvidoria='central-ajuda'
                            onClick={() => {
                              sendDatalayerEvent({
                                section: 'dobra_02',
                                element_action: 'click button',
                                element_name: 'Central de Ajuda',
                                section_name: 'Estamos aqui para te atender',
                                redirect_url: 'https://ajuda.bancointer.com.br',
                              })
                            }}
                          >{' '}Central de Ajuda.
                          </a>
                        </span> Você também pode mandar uma mensagem no chat do app.
                      </p>
                    </div>
                  </BoxedShadow>
                </animated.div>
              </div>
            </div>
          </div>
        </section>

        <section ref={formRef} id='form-ouvidoria'>
          <div className='container py-4'>
            <h2 style={animatedForm[0]} className='fs-24 fs-lg-40 lh-30 lh-lg-50 text-center text-grayscale--500'>
              Fale com a Ouvidoria
            </h2>
            <p style={animatedForm[1]} className='fs-18 lh-22 text-grayscale--500 text-center'>
              Se você já tem um protocolo registrado, deixe suas informações que reavaliaremos sua reclamação.
            </p>
            <div style={animatedForm[2]}>
              <FaleOuvidoria />
            </div>
          </div>
        </section>

        <section ref={weHelpYouRef} className='bg-gray'>
          <div className='container py-5'>
            <div className='row align-items-center'>
              <animated.div style={animatedWeHelpYou[2]} className='col-12 col-md-6'>
                <ImageSizeLeft>
                  <Img fluid={data.ajudamosVoce.fluid} alt='Cliente ao telefone' />
                </ImageSizeLeft>
              </animated.div>
              <div className='col-12 col-md-6 col-lg-5 offset-lg-1 mt-4'>
                <animated.h2 style={animatedWeHelpYou[0]} className='fs-24 fs-lg-32 fs-xl-40 lh-30 lh-lg-40 lh-xl-50 text-grayscale--500'>
                  <span className='d-lg-block d-xl-inline'>Estamos do lado da </span><span className='d-lg-block d-xl-inline'>defesa do consumidor</span>
                </animated.h2>
                <animated.p style={animatedWeHelpYou[1]} className='fs-18 lh-22 text-grayscale--500'>
                  Apoiamos a plataforma consumidor.gov, que é uma iniciativa pública disponibilizada pelo Governo Federal,
                  como alternativa para mediar e solucionar conflitos entre as instituições e consumidores.
                </animated.p>
                <animated.p style={animatedWeHelpYou[1]} className='fs-18 lh-22 text-grayscale--500'>Acesse agora:
                  <span className='text-orange--extra fw-600'>
                    <a
                      href='https://www.consumidor.gov.br/'
                      target='blank'
                      data-home-ouvidoria='portal-consumidor'
                      onClick={() => {
                        sendDatalayerEvent({
                          section: 'dobra_03',
                          element_action: 'click button',
                          element_name: 'consumidor.gov.br',
                          section_name: 'Estamos do lado da defesa do consumidor',
                          redirect_url: 'https://www.consumidor.gov.br/',
                        })
                      }}
                    >
                      {' '}consumidor.gov.br
                    </a>
                  </span>
                </animated.p>
              </div>
            </div>
          </div>
        </section>

        <section ref={reportsRef}>
          <div className='container py-5'>
            <div className='row align-items-center'>
              <animated.div style={animatedReports[2]} className='col-12 col-md-6 offset-lg-1 order-md-last'>
                <ImageSize>
                  <Img fluid={data.nossosRelatorios.fluid} alt='Mulher sorrindo' />
                </ImageSize>
              </animated.div>
              <div className='col-12 col-md-6 col-lg-5 mt-4 px-0'>
                <animated.h2 style={animatedReports[0]} className='fs-24 fs-lg-32 fs-xl-40 lh-30 lh-lg-40 lh-xl-50 text-grayscale--500'>Acesse nossos <span className='d-lg-block'>relatórios</span> de ouvidoria</animated.h2>
                <animated.p style={animatedReports[1]} className='fs-18 lh-22 text-grayscale--500 mb-4'>
                  Conheça as atividades da nossa Ouvidoria e um pouco do nosso trabalho realizado para melhorar a experiência de nossos clientes.
                </animated.p>
                <animated.div style={animatedReports[2]}>
                  <UL>
                    {OmbudsmanReportsJSON.map((item: OmbudsmanProps) => (
                      <li key={item.uuid} className='fs-14 lh-17 mb-4 mb-md-3 fw-600 d-flex justify-content-start align-items-center'>
                        <Icon width='32' height='32' color='orange--base' icon='action/portabilidade' directory='v2' />
                        <a
                          href={item.pdf.file.url}
                          title={item.pdf.name}
                          target='blank'
                          data-home-ouvidoria={item.pdf.file.name}
                          onClick={() => {
                            sendDatalayerEvent({
                              section: 'dobra_05',
                              element_action: 'click button',
                              element_name: item.pdf.name,
                              section_name: 'Acesse nossos relatórios de ouvidoria',
                              redirect_url: item.pdf.file.url,
                            })
                          }}
                        >
                          {item.pdf.name}
                        </a>
                      </li>
                    ))}
                  </UL>
                </animated.div>
              </div>
            </div>
          </div>
        </section>

        <section ref={newsRef} className='bg-gray'>
          <div className='container py-5'>
            <div className='row'>
              <div className='col-12'>
                <animated.h2 style={animatedNews[0]} className='fs-24 fs-lg-32 fs-xl-40 lh-30 lh-lg-40 lh-xl-50 text-grayscale--500 text-md-center'>Siga o Inter</animated.h2>
                <animated.p style={animatedNews[1]} className='fs-18 lh-22 text-grayscale--500 mb-4 text-md-center'>Fique por dentro do que acontece no nosso dia a dia e fale com a gente, se precisar.</animated.p>
              </div>
              <animated.div style={animatedNews[2]} className='row'>
                {
                  StayInJSON.map((item: StayInProps) => (
                    <BoxCard className='col-12 col-md-6 mx-auto pt-3 pb-2' key={item.title}>
                      <div className='d-flex flex-column pl-4 pr-3 pt-4 pb-3 py-md-4 border rounded-4 justify-content-end voce-por-dentro'>
                        <div className='row mx-0'>
                          <div className='col-12 col-md-12 col-xl-6 align-self-end'>
                            <div className='d-flex align-items-center'>
                              <div className='mr-2 mb-4'><Icon width='22' height='22' icon={item.icon} directory='v2' color='orange--extra' /></div>
                              <span className='text-orange--extra fs-16 lh-20 fw-600 mb-4'>{item.title}</span>
                            </div>
                            <h3 className='fs-16 lh-18 pb-1 fw-600 text-grayscale--500 mb-0'>
                              {item.description}
                            </h3>
                          </div>
                          <div className='col-12 col-xl-6 mt-2 mb-xl-2 align-self-end text-xl-right pr-xl-0'>
                            <a
                              href={item.url} title='Veja nossos relatórios'
                              target='_blank'
                              rel='noopener noreferrer'
                              className='fs-14 lh-17 fw-400 text-orange--extra'
                              data-home-ouvidoria={item.dataValue}
                              onClick={() => {
                                sendDatalayerEvent({
                                  section: 'dobra_06',
                                  element_action: 'click button',
                                  element_name: item.buttonText,
                                  section_name: 'Siga o Inter',
                                  redirect_url: item.url,
                                })
                              }}
                            >
                              {item.buttonText}
                              <Icon width='27.12' height='24' color='orange--base ml-2' icon='navigation/arrow-right' directory='v2' />
                            </a>
                          </div>
                        </div>
                      </div>
                    </BoxCard>
                  ))
                }
              </animated.div>
            </div>
          </div>
        </section>
      </Layout>
    </Wrapper>
  )
}

export default Ouvidoria
